<template>
    <div class="page-box">
        <div class="page-title flex-center between-box">
            <span>当前位置：项目管理 > <b>项目结算单</b></span>
            <div class="flex-center" @click="back">
                <i class="iconfont iconjiantou-copy-copy"></i>返回
            </div>
        </div>
        <div class="project-info">
            <div class="project-condition">
                <div class="flex-center">
                    <el-button type="primary" v-if="isAdmin" @click="addSettlement"
                    >新增结算单
                    </el-button
                    >
                </div>
                <div class="flex-center"></div>
            </div>

            <div class="settlement-tab">
                <el-table
                        :data="settlement"
                        header-row-class-name="table-header"
                        style="width: 100%">
                    <el-table-column width="100" label="序号" type="index" align="center">
                    </el-table-column>
                    <el-table-column align="center" prop="table_name" label="结算单位">
                    </el-table-column>
                    <el-table-column align="center" prop="add_time" label="创建时间">
                    </el-table-column>
                    <el-table-column label="操作" width="200px" align="center">
                        <template slot-scope="scope">
                            <div class="table-operate">
                                <el-button
                                        type="text"
                                        size="small"
                                        style="font-size: 14px"
                                        v-if="scope.row.eid !== 0"
                                        @click="detailItem(scope.row.id)"
                                >查看
                                </el-button>
                                <el-button
                                        type="text"
                                        size="small"
                                        style="font-size: 14px"
                                        v-if="scope.row.eid === 0 && isAdmin"
                                        @click="detailItem(scope.row.id)"
                                >编辑
                                </el-button>
                                <el-button
                                        type="text"
                                        size="small"
                                        style="font-size: 14px; color: #e4393c"
                                        v-if="scope.row.eid === 0 && isAdmin"
                                        @click="closeItem(scope.row.id)"
                                >删除
                                </el-button>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "settlement",
        data() {
            return {
                id: "",
                isAdmin: false,
                settlement: [],
            };
        },
        created() {
            this.id = this.$route.query.id;
            this.isAdmin = this.user.mid === Number(this.$route.query.uid)
            this.$emit("setIndex", [1, 1]);
            this.init();

        },
        computed: {
            user() {
                return this.$store.state.user;
            },
        },
        methods: {
            init() {
                this.$api.apiContent
                    .specialTableList({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                        pid: this.id,
                        sign: this.$tools.SHA256(
                            {
                                token: this.$store.state.user.token,
                                timestamp: this.$tools.requestTime(),
                                pid: this.id,
                            },
                            this.$store.state.user.key
                        ),
                    })
                    .then((res) => {
                        this.settlement = res.data;
                    });
            },
            closeItem(id) {
                this.$confirm("此操作将删除该结算单, 是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                    this.$api.apiContent
                        .specialTableDel({
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            id: id,
                            sign: this.$tools.SHA256(
                                {
                                    token: this.$store.state.user.token,
                                    timestamp: this.$tools.requestTime(),
                                    id: id,
                                },
                                this.$store.state.user.key
                            ),
                        })
                        .then(() => {
                            this.$message.success("删除成功");
                            this.init();
                        })
                        .catch((e) => {
                            this.$message.error(e.msg);
                        });
                });
            },
            detailItem(id) {
                this.$router.push("/home/settlementDetail?id=" + id);
            },
            addSettlement() {
                this.$prompt("请输入结算单位", "新增结算单", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                }).then(({value}) => {
                    if (!value) {
                        this.$message.error("请填写结算单位");
                    } else {
                        this.addspecialTable(value);
                    }
                });
            },
            addspecialTable(value) {
                this.$api.apiContent
                    .specialTableIns({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                        pid: this.id,
                        table_name: value,
                        sign: this.$tools.SHA256(
                            {
                                token: this.$store.state.user.token,
                                timestamp: this.$tools.requestTime(),
                                pid: this.id,
                                table_name: value,
                            },
                            this.$store.state.user.key
                        ),
                    })
                    .then((res) => {
                        this.init();
                        console.log(res);
                    });
            },
            //  返回
            back() {
                this.$router.go(-1);
            },
        },
    };
</script>

<style scoped lang="scss">
    .page-box {
        .page-title {
            height: 40px;
            margin-bottom: 10px;

            span {
                font-size: 18px;
                line-height: 36px;
                color: #999999;

                b {
                    color: #333;
                    font-weight: normal;
                }
            }

            div {
                color: #3b77e7;
                font-size: 20px;
                cursor: pointer;

                i {
                    display: block;
                    width: 24px;
                    height: 24px;
                    text-align: center;
                    line-height: 24px;
                    margin-right: 5px;
                    border-radius: 100%;
                    color: #fff;
                    font-size: 14px;
                    background-color: #3b77e7;
                }
            }
        }
        .project-info {
            width: 100%;
            background-color: #ffffff;
            border-radius: 6px;
            overflow: hidden;
            min-height: 750px;
            .project-condition {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                padding: 24px 28px 16px;
                border-bottom: 2px solid #f5f5f5;

                .title-tab {
                    .active {
                        background: #3b77e7;
                        color: #fff;
                    }
                    .settlement {
                        margin-left: 10px;
                    }
                }

                .el-select {
                    margin-right: 20px;
                }

                .el-input {
                    margin: 0 20px;
                    width: 268px;
                }
            }
            .settlement-tab {
                padding: 30px;
            }
        }
    }
</style>
